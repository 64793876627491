import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import StartupSVG from '../assets/images/illustrator/Startup_SVG.svg';
import WordpressImg from '../assets/images/apps/wordpress.svg';
import DiscourseImg from '../assets/images/apps/discourse.svg';
import BookStackImg from '../assets/images/apps/bookstack.svg';
import GhostImg from '../assets/images/apps/ghost.svg';
import ConsoleImg from '../assets/images/apps/console.png';
import Features from "../components/features";
import Trial from "../components/trial";
import { PricingData, PricingHelpers } from "../data/pricing";

const IndexPage = () => {
    const appProviders = useStaticQuery(graphql`
    query {
        allNitroApplicationProvider {
            edges {
                node {
                id
                name
                plans {
                    id
                    name
                    priceBilledMonthly
                    features {
                    name
                    }
                }
                }
            }
        }
    }
    `).allNitroApplicationProvider.edges.map(e => e.node);

  return (<Layout>
    <section className="bg-half-170 d-table w-100">
        <div className="container">
            <div className="row mt-5 align-items-center">
                <div className="col-lg-7 col-md-7">
                    <div className="title-heading me-lg-4">
                        <h1 className="heading mb-3">1-Click, Fully Managed Hosting.</h1>
                        <p className="para-desc text-muted">
                            Choose from our library of managed applications and let us run it for you. From <strong>WordPress</strong> to
                            <strong> Discourse</strong> we've got you covered.
                        </p>
                        <p className="para-desc text-muted">
                            Nitro doesn't just launch a VM for you -- it fully manages your instance, unlike most other cloud providers. 
                            See the <a href="/pricing#standard-features">full list of features</a> to see what managed hosting provides.
                        </p>

                        <div className="mt-4 mb-1">
                            <a href="https://console.nitropowered.io/signup" className="btn btn-primary mt-2 me-2"><i
                                    ></i> Launch an app for free</a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <img src={StartupSVG} alt=""/>
                </div>
            </div>
        </div>
    </section>

    <section className="section bg-light mt-0 mt-md-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                    <div className="section-title mb-4 pb-2">
                        <h4 className="title mb-4">Application Library</h4>
                        <p className="text-muted para-desc mx-auto mb-0">
                            Join <span className="text-primary fw-bold">Nitro</span> and run any of our <a href="#">many
                                applications</a> in minutes.
                            Run your application in any of our <a href="#">many regions</a> and keep your application
                            close to your users.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
                    <img src={WordpressImg} className="avatar avatar-ex-sm" alt=""/>
                </div>

                <div className="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
                    <img src={GhostImg} className="avatar avatar-ex-sm" alt=""/>
                </div>

                <div className="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
                    <img src={DiscourseImg} className="avatar avatar-ex-sm" alt=""/>
                </div>

                <div className="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
                    <img src={BookStackImg} className="avatar avatar-ex-sm" alt=""/>
                </div>
            </div>
        </div>
    </section>

    <section className="section">
        <Features/>

        <div className="container mt-100 mt-60">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                    <img src={ConsoleImg} className="img-fluid shadow rounded" alt=""/>
                </div>

                <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="section-title ms-lg-5">
                        <h4 className="title mb-4">The Nitro Powered console lets you manage all your applications from one place.
                        </h4>
                        {/*<!--<ul className="list-unstyled text-muted">
                            <li className="mb-0"><span className="text-primary h5 me-2"><i
                                        className="uil uil-check-circle align-middle"></i></span>Single pane of glass</li>
                            <li className="mb-0"><span className="text-primary h5 me-2"><i
                                        className="uil uil-check-circle align-middle"></i></span>Hands-off deployment of software
                            </li>
  </ul>*/}
                        {/*<a href="javascript:void(0)" className="mt-3 h6 text-primary">Find Out More <i
                                className="uil uil-angle-right-b"></i></a>*/}
                    </div>
                </div>
            </div>
        </div>

        <div className="container mt-100 mt-60">
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                    <div className="section-title mb-4 pb-2">
                        <h4 className="title mb-4">Pricing</h4>
                        <p className="text-muted para-desc mb-0 mx-auto">All applications launched use metered billing -- so
                            you only pay for what you use.</p>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                {appProviders.slice(0, 4).map(data => (
                <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div className="card pricing-rates business-rate shadow bg-light border-0 rounded">
                        <div className="card-body">
                            <h6 className="title fw-bold text-uppercase text-primary mb-4">{data.name}</h6>
                            <span>Starting at </span>
                            <div className="d-flex mb-4">
                                <span className="h4 mb-0 mt-2">$</span>
                                <span className="price h1 mb-0">{data.plans.find(p => p.id == 'basic').priceBilledMonthly}</span>
                                <span className="h4 align-self-end mb-1">/month</span>
                                <span className="h4 align-self-end mb-1"></span>
                            </div>

                            <ul className="list-unstyled mb-0 ps-0">
                                <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                            className="uil uil-check-circle align-middle"></i></span>Free SSL</li>
                                <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                            className="uil uil-check-circle align-middle"></i></span>Custom Domain Name</li>
                                <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                            className="uil uil-check-circle align-middle"></i></span>Daily Backups</li>
                                <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                            className="uil uil-check-circle align-middle"></i></span>Fully Managed</li>
                                <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                            className="uil uil-check-circle align-middle"></i></span>Unlimited Pageviews</li>
                            </ul>
                        </div>
                    </div>
                </div>
                ))}

            </div>
        </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center mt-4">
                            <Link to="/pricing#plans" className="btn btn-primary mt-2 me-2"><i
                                    ></i> See full pricing details</Link>
                    </div>
                </div>
            </div>
        <Trial/>

        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <h4 className="title mb-4">Flexible Tiers</h4>
                            <p className="text-muted para-desc mb-0 mx-auto">
                                Whether you are hosting a small, personal blog or a large, high-traffic one, we've go
                                you covered. When you launch your application you can choose the tier that fits best for
                                you.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div className="card pricing-rates business-rate shadow bg-light border-0 rounded">
                            <div className="card-body">
                                <h6 className="title fw-bold text-uppercase text-primary mb-4">Basic</h6>

                                <div className="d-flex mb-4">
                                    <span className="h5 align-self-end mb-1">Good for small, low traffic sites</span>
                                </div>
                                <ul className="list-unstyled mb-0 ps-0">
                                    <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                                className="uil uil-check-circle align-middle"></i></span>Shared CPU</li>
                                    <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                                className="uil uil-check-circle align-middle"></i></span>Shared Database
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div className="card pricing-rates business-rate shadow bg-white border-0 rounded">
                            <div className="ribbon ribbon-right ribbon-warning overflow-hidden"><span
                                    className="text-center d-block shadow small h6">Best</span></div>
                            <div className="card-body">
                                <h6 className="title fw-bold text-uppercase text-primary mb-4">Power</h6>

                                <div className="d-flex mb-4">
                                    <span className="h5 align-self-end mb-1">Best all around</span>
                                </div>
                                <ul className="list-unstyled mb-0 ps-0">
                                    <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                                className="uil uil-check-circle align-middle"></i></span>Shared CPU</li>
                                    <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                                className="uil uil-check-circle align-middle"></i></span>Dedicated Database
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div className="card pricing-rates business-rate shadow bg-light border-0 rounded">
                            <div className="card-body">
                                <h6 className="title fw-bold text-uppercase text-primary mb-4">Turbo</h6>

                                <div className="d-flex mb-4">
                                    <span className="h5 align-self-end mb-1">Best for high traffic sites</span>
                                </div>
                                <ul className="list-unstyled mb-0 ps-0">
                                    <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                                className="uil uil-check-circle align-middle"></i></span>Dedicated CPU</li>
                                    <li className="h6 text-muted mb-0"><span className="text-primary h5 me-2"><i
                                                className="uil uil-check-circle align-middle"></i></span>Dedicated Database
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center mt-4">
                            <Link to="/pricing#plans" className="btn btn-primary mt-2 me-2"><i
                                    ></i> See full pricing details</Link>
                    </div>
                </div>
            </div>
            </div>
        </section>

        <br />
        <br />
        <br />
        <br />
        <br />


    </section>
  </Layout>);
}

export default IndexPage
