import * as React from 'react';

const Features = () => (
    <>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <i class="uil uil-browser h1 text-primary"></i>
                        </div>

                        <div class="content mt-4">
                            <h5>Fully Managed</h5>
                            <p class="text-muted mb-0">With <span class="text-primary fw-bold">Nitro</span>, all
                                applications are fully managed. We handle keeping your software up to date, secure, and
                                fast. </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <i class="uil uil-cloud-computing h1 text-primary"></i>
                        </div>

                        <div class="content mt-4">
                            <h5>Backups</h5>
                            <p class="text-muted mb-0">All of our plans include automated, daily backups. Backups can be
                                easily restored in the event you change something and need to back to the last, good
                                version.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <i class="uil uil-server h1 text-primary"></i>
                        </div>

                        <div class="content mt-4">
                            <h5>Free SSL</h5>
                            <p class="text-muted mb-0">SSL certificates are included, whether you want to use your own
                                custom domain, or a Nitro provided one.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <i class="uil uil-server-network h1 text-primary"></i>
                        </div>

                        <div class="content mt-4">
                            <h5>Multiple Locations</h5>
                            <p class="text-muted mb-0">Your application can be launched in any of our multiple regions
                                around the world, so your site can be close to the users that matter.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <i class="uil uil-database-alt h1 text-primary"></i>
                        </div>

                        <div class="content mt-4">
                            <h5>Metered Billing</h5>
                            <p class="text-muted mb-0">With Nitro you only pay for the time the application runs (rounded up to the hour).
                                See our <a href="/pricing">pricing plans</a> for more information.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <i class="uil uil-code-branch h1 text-primary"></i>
                        </div>

                        <div class="content mt-4">
                            <h5>Fast, Always Online</h5>
                            <p class="text-muted">Applications are run in a highly available, fault tolerant
                                environment, to ensure your site never goes offline.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
);

export default Features;