import * as React from 'react';

const Trial = () => (
        <div class="container mt-100 mt-60">
            <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
                <div class="row align-items-end">
                    <div class="col-md-8">
                        <div class="section-title text-md-start text-center">
                            <h4 class="title mb-3 text-white title-dark">Start your free 2 week trial today</h4>
                            <p class="text-white-50 mb-0">No credit card required, launch any application today with a 2
                                week trial, no risk.</p>
                        </div>
                    </div>

                    <div class="col-md-4 mt-4 mt-sm-0">
                        <div class="text-md-end text-center">
                            <a href="https://console.nitropowered.io/signup" class="btn btn-light">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

);

export default Trial;