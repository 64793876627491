const PricingData = [
    {
        "product": "WordPress",
        "tiers": {
            "basic": {"monthlyPrice": 5.0},
            "power": {"monthlyPrice": 15.0},
            "turbo": {"monthlyPrice": 25.0},
        }
    },
    {
        "product": "Ghost",
        "tiers": {
            "basic": {"monthlyPrice": 5.0},
            "power": {"monthlyPrice": 15.0},
            "turbo": {"monthlyPrice": 25.0},
        }
    },
    {
        "product": "Discourse",
        "tiers": {
            "basic": {"monthlyPrice": 20.0},
            "power": {"monthlyPrice": 49.99},
            "turbo": {"monthlyPrice": 75.0},
        }
    },
    {
        "product": "Wiki.js",
        "tiers": {
            "basic": {"monthlyPrice": 5.0},
            "power": {"monthlyPrice": 15.0},
            "turbo": {"monthlyPrice": 25.0},
        }
    }
];

const PricingHelpers = {
    calculateHourlyPrice: (dataTier) => {
        return Math.round((dataTier.priceBilledMonthly / (31 * 24)) * 10000) / 10000;
    }
};

export {PricingData, PricingHelpers};